/**
 *
 * ConsoleBreadcrumbs
 *
 */

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import { Typography } from '@mamacrowd/ui-kit';
import { Paper, Breadcrumbs } from '@mui/material';

import Link from 'basic/Link';
import FormattedMessage from 'components/FormattedMessage';
import sidebarMenuMessages from 'console/components/SidebarMenu/messages';
import {
  makeSelectLocation,
  makeSelectConsoleActiveCampaign,
  makeSelectConsoleActiveCompanyId,
  makeSelectConsoleActiveOrderId,
  makeSelectConsoleActiveUserId,
  makeSelectConsoleActiveMessageId,
  makeSelectConsoleActiveBannerId,
  makeSelectConsoleActiveArticleId,
  makeSelectConsoleActivePartnerId,
  makeSelectConsoleActiveTestimonialId,
  makeSelectConsoleActivePageId,
  makeSelectConsoleActivePressId,
  makeSelectConsoleActiveFaqId,
  makeSelectConsoleActiveSettingId,
} from 'containers/App/selectors';

import {
  CONSOLE_PREFIX,
  CONSOLE_LIST_CAMPAIGNS_ROUTE,
  CONSOLE_LIST_COMPANIES_ROUTE,
  CONSOLE_LIST_ORDERS_ROUTE,
  CONSOLE_LIST_USERS_ROUTE,
  CONSOLE_LIST_MESSAGES_ROUTE,
  CONSOLE_LIST_BANNERS_ROUTE,
  CONSOLE_LIST_ARTICLES_ROUTE,
  CONSOLE_LIST_PARTNERS_ROUTE,
  CONSOLE_LIST_TESTIMONIALS_ROUTE,
  CONSOLE_LIST_PAGES_ROUTE,
  CONSOLE_LIST_PRESS_ROUTE,
  CONSOLE_LIST_FAQ_ROUTE,
  CONSOLE_LIST_SETTINGS_ROUTE,
} from 'utils/constants';

import { useStyle } from './Breadcrumbs.style';
import consoleBreadcrumbsMessages from './messages';

const messages = { ...consoleBreadcrumbsMessages, ...sidebarMenuMessages };

function ConsoleBreadcrumbs({
  history,
  location,
  activeCampaign,
  companyId,
  orderId,
  userId,
  messageId,
  bannerId,
  articleId,
  partnerId,
  testimonialId,
  pageId,
  pressId,
  faqId,
  settingId,
}) {
  const classes = useStyle();

  const [breadcrumbObject, setBreadcrumbsObject] = useState([]);

  const checkIfInRoute = route =>
    location.pathname.indexOf(`${CONSOLE_PREFIX}${route}`) > -1;

  useEffect(() => {
    const breadcrumb = location && location.pathname ? location.pathname.split('/').splice(2).filter(v => !!v) : []; // eslint-disable-line prettier/prettier
    setBreadcrumbsObject(breadcrumb);
  }, [
    location,
    activeCampaign,
    companyId,
    orderId,
    userId,
    messageId,
    bannerId,
    articleId,
    partnerId,
    testimonialId,
    pageId,
    pressId,
    faqId,
    settingId,
  ]);

  const convertId = item => {
    if (checkIfInRoute(CONSOLE_LIST_CAMPAIGNS_ROUTE)) {
      return activeCampaign && activeCampaign.id === item
        ? activeCampaign.title
        : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_COMPANIES_ROUTE)) {
      return item === companyId ? companyId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_ORDERS_ROUTE)) {
      return item === orderId ? orderId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_USERS_ROUTE)) {
      return item === userId ? userId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_MESSAGES_ROUTE)) {
      return item === messageId ? messageId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_BANNERS_ROUTE)) {
      return item === bannerId ? bannerId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_ARTICLES_ROUTE)) {
      return item === articleId ? articleId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_PARTNERS_ROUTE)) {
      return item === partnerId ? partnerId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_TESTIMONIALS_ROUTE)) {
      return item === testimonialId ? testimonialId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_PAGES_ROUTE)) {
      return item === pageId ? pageId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_PRESS_ROUTE)) {
      return item === pressId ? pressId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_FAQ_ROUTE)) {
      return item === faqId ? faqId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_SETTINGS_ROUTE)) {
      return item === settingId ? settingId : item;
    }
    return item;
  };

  const handleClickSection = section => {
    if (section) {
      history.push(section);
    }
  };

  return (
    <Paper elevation={0} className={classes.breadcrumbs}>
      <Breadcrumbs aria-label="breadcrumb" separator="&#62;">
        {breadcrumbObject.map(item => {
          if (item) {
            let name = item.split('-');
            if (name.length) {
              name = name.reduce(
                (a, b) => a + b[0].toUpperCase() + b.substring(1, b.length),
              );
            }
            let link = null;
            if (item !== breadcrumbObject[breadcrumbObject.length - 1]) {
              const index = location.pathname.indexOf(item);
              link = location.pathname.substring(0, index + item.length);
            }
            const children =
              item === breadcrumbObject[2] ? (
                convertId(item)
              ) : (
                <FormattedMessage messages={messages} messageId={name} />
              );
            return link ? (
              <Link key={item} to={link} className={classes.textSection}>
                {children}
              </Link>
            ) : (
              <Typography
                key={item}
                color={link ? 'primary' : 'textPrimary'}
                onClick={() => {
                  handleClickSection(link);
                }}
              >
                {children}
              </Typography>
            );
          }
          return null;
        })}
      </Breadcrumbs>
    </Paper>
  );
}

ConsoleBreadcrumbs.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  activeCampaign: PropTypes.object,
  companyId: PropTypes.string,
  orderId: PropTypes.string,
  userId: PropTypes.string,
  messageId: PropTypes.string,
  bannerId: PropTypes.string,
  articleId: PropTypes.string,
  partnerId: PropTypes.string,
  testimonialId: PropTypes.string,
  pageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pressId: PropTypes.string,
  faqId: PropTypes.string,
  settingId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  activeCampaign: makeSelectConsoleActiveCampaign(),
  companyId: makeSelectConsoleActiveCompanyId(),
  orderId: makeSelectConsoleActiveOrderId(),
  userId: makeSelectConsoleActiveUserId(),
  messageId: makeSelectConsoleActiveMessageId(),
  bannerId: makeSelectConsoleActiveBannerId(),
  articleId: makeSelectConsoleActiveArticleId(),
  partnerId: makeSelectConsoleActivePartnerId(),
  testimonialId: makeSelectConsoleActiveTestimonialId(),
  pageId: makeSelectConsoleActivePageId(),
  pressId: makeSelectConsoleActivePressId(),
  faqId: makeSelectConsoleActiveFaqId(),
  settingId: makeSelectConsoleActiveSettingId(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withRouter)(ConsoleBreadcrumbs);
