/**
 *
 * SocialMedia
 *
 */

import { IconButton } from '@mui/material';

import Box from 'components/Box';

import FacebookIcon from 'images/social-facebook-icon.svg';
import LinkedinIcon from 'images/social-linkedin-icon.svg';
import InstagramIcon from 'images/social-instagram-icon.svg';
import XIcon from 'images/social-x-icon.svg';
import YoutubeIcon from 'images/social-youtube-icon.svg';

import {
  FACEBOOK_SOCIAL_URL,
  TWITTER_SOCIAL_URL,
  INSTAGRAM_SOCIAL_URL,
  LINKEDIN_SOCIAL_URL,
  YOUTUBE_SOCIAL_URL,
} from 'utils/constants';

import { useStyle } from './SocialMedia.style';

function SocialMedia() {
  const classes = useStyle();

  return (
    <Box className={classes.socialMediaContainer}>
      <IconButton
        className={classes.socialIconContainer}
        href={FACEBOOK_SOCIAL_URL}
        target="_blank"
        rel="noreferrer"
        size="large"
      >
        <img src={FacebookIcon} className={classes.socialIcon} alt="facebook" />
      </IconButton>
      <IconButton
        className={classes.socialIconContainer}
        href={TWITTER_SOCIAL_URL}
        target="_blank"
        rel="noreferrer"
        size="large"
      >
        <img src={XIcon} className={classes.socialIcon} alt="twitter" />
      </IconButton>
      <IconButton
        className={classes.socialIconContainer}
        href={INSTAGRAM_SOCIAL_URL}
        target="_blank"
        rel="noreferrer"
        size="large"
      >
        <img
          src={InstagramIcon}
          className={classes.socialIcon}
          alt="instagram"
        />
      </IconButton>
      <IconButton
        className={classes.socialIconContainer}
        href={LINKEDIN_SOCIAL_URL}
        target="_blank"
        rel="noreferrer"
        size="large"
      >
        <img src={LinkedinIcon} className={classes.socialIcon} alt="linkedin" />
      </IconButton>
      <IconButton
        className={classes.socialIconContainer}
        href={YOUTUBE_SOCIAL_URL}
        target="_blank"
        rel="noreferrer"
        size="large"
      >
        <img src={YoutubeIcon} className={classes.socialIcon} alt="youtube" />
      </IconButton>
    </Box>
  );
}

export default SocialMedia;
