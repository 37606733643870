// #MMC2: add urls in webpack

import {
  HOW_TO_INVEST_ROUTE,
  PRESS_ROUTE,
  NEWS_PAGE_ROUTE,
  CARRERS_PAGE_ROUTE,
  // EXTERNAL_LINK_CONSOB,
  EXTERNAL_LINK_DISPUTES,
  FAQ_ROUTE,
  COOKIES_ROUTE,
  WHO_ARE_ROUTE,
  COMPANY_INFO_ROUTE,
  CROWDFUNDING_SERVICE_ROUTE,
  RELATED_INVESTMENTS_COSTS_ROUTE,
  ADDITIONAL_RISK_WARNING_ROUTE,
  SIM_HELP_PAGE_ROUTE,
  BENEFITS_ROUTE,
  TRANSFER_TYPE_TERMS_ROUTE,
  TRUSTEE_SERVICE_ROUTE,
  CONTACTS_ROUTE,
  TERMS_ROUTE,
  PRIVACY_ROUTE,
  CLAIMS_POLICY_ROUTE,
  CONFLICT_INTEREST_POLICY_ROUTE,
  STATISTICS_ROUTE,
  POWER_USER_ROUTE,
  TERMS_AND_CONDITION_POWER_USER_ROUTE,
  INDEX_KNOWLEDGE_INVESTOR_ROUTE,
} from 'utils/constants';

export type FooterLinkType = {
  translationId: string;
  link?: string;
};

export interface FooterLinksProps {
  translationIdTitle: string;
  links: FooterLinkType[];
}

export const mamacrowdSection: FooterLinksProps = {
  translationIdTitle: 'mamacrowd',
  links: [
    {
      translationId: 'aboutUs',
      link: WHO_ARE_ROUTE,
    },
    {
      translationId: 'press',
      link: PRESS_ROUTE,
    },
    {
      translationId: 'powerUser',
      link: POWER_USER_ROUTE,
    },
    {
      translationId: 'news',
      link: NEWS_PAGE_ROUTE,
    },
    {
      translationId: 'carrers',
      link: CARRERS_PAGE_ROUTE,
    },
    {
      translationId: 'companyInformation',
      link: COMPANY_INFO_ROUTE,
    },
    {
      translationId: 'statisticsRealEstate',
      link: STATISTICS_ROUTE,
    },
    // {
    //   translationId: 'regConsob',
    //   link: EXTERNAL_LINK_CONSOB,
    // },
  ],
};

export const crowdfundingServicesSection: FooterLinksProps = {
  translationIdTitle: 'crowdfundingServices',
  links: [
    {
      translationId: 'investorGuide',
      link: INDEX_KNOWLEDGE_INVESTOR_ROUTE,
    },
    {
      translationId: 'crowdfundingServices',
      link: CROWDFUNDING_SERVICE_ROUTE,
    },
    {
      translationId: 'investmentCosts',
      link: RELATED_INVESTMENTS_COSTS_ROUTE,
    },
    {
      translationId: 'riskWarning',
      link: ADDITIONAL_RISK_WARNING_ROUTE,
    },
    {
      translationId: 'alternativeRegimeOfShares',
      link: SIM_HELP_PAGE_ROUTE,
    },
    {
      translationId: 'taxBenefits',
      link: BENEFITS_ROUTE,
    },
    {
      translationId: 'financialDisputes',
      link: EXTERNAL_LINK_DISPUTES,
    },
    {
      translationId: 'cdtRegime',
      link: TRANSFER_TYPE_TERMS_ROUTE,
    },
    {
      translationId: 'trustCompany',
      link: TRUSTEE_SERVICE_ROUTE,
    },
  ],
};

export const supportSection: FooterLinksProps = {
  translationIdTitle: 'support',
  links: [
    {
      translationId: 'howToStart',
      link: HOW_TO_INVEST_ROUTE,
    },
    {
      translationId: 'faq',
      link: FAQ_ROUTE,
    },
    {
      translationId: 'contacts',
      link: CONTACTS_ROUTE,
    },
  ],
};

export const legalSection: FooterLinksProps = {
  translationIdTitle: 'legal',
  links: [
    {
      translationId: 'termsConditions',
      link: TERMS_ROUTE,
    },
    {
      translationId: 'powerUserTermsConditions',
      link: TERMS_AND_CONDITION_POWER_USER_ROUTE,
    },
    {
      translationId: 'privacyPolicy',
      link: PRIVACY_ROUTE,
    },
    {
      translationId: 'cookiesPolicy',
      link: COOKIES_ROUTE,
    },
    {
      translationId: 'recoveryPolicy',
      link: CLAIMS_POLICY_ROUTE,
    },
    {
      translationId: 'policyOfInterest',
      link: CONFLICT_INTEREST_POLICY_ROUTE,
    },
    {
      translationId: 'handleCookies',
    },
  ],
};
